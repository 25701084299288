<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header justify-content-center">
          <div class="input-group input-group-flush d-flex flex-row-reverse">
            <input v-model.trim="search" class="form-control list-search" type="search" placeholder="Search" />
            <span class="input-group-text border-0">
              <i class="fe fe-search" @click.prevent="fetchCompanyGroups(true)"></i>
            </span>
          </div>
        </div>

        <b-table striped hover selectable responsive show-empty :items="companyGroups" :fields="fields" :current-page="currentPage" :busy="fetchingGroups" @row-clicked="viewCorporateGroup">
          <template #table-busy>
            <div class="d-flex flex-column justify-content-center align-items-center">
              <div class="spinner-border" role="status"></div>
              <p class="text-center mt-2"><strong>Loading...</strong></p>
            </div>
          </template>
          <template #empty>
            <p class="text-center text-secondary">
              {{
                search
                ? `No records found for search value: "${search}"`
                : 'No records available'
              }}
            </p>
          </template>
          <template #cell(serialNumber)="data">
            {{ data.index + 1 }}
          </template>

          <template #cell(group_name)="data">
            {{ data.item.name }}
          </template>
          <template #cell(number_of_staffs)="data">
            {{ data.item.members.length }}
          </template>
          <template #cell(created_at)="data">
            {{ data.item.created_at | date('dd/MM/yyyy') }}
          </template>
        </b-table>

        <div class="card-footer" v-if="totalRecords">
          <div class="row align-items-center">
            <div class="col">
              <span class="text-dark font-weight-bold">{{
                tablePageText
              }}</span>
            </div>
            <div class="col-auto">
              <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="pageSize"></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import UserAvatar from '@/components/core/UserAvatar.vue'
import TableView from '@/mixins/tableview'

export default {
  name: 'CompanyGroups',
  mixins: [TableView],
  components: {
    // UserAvatar,
  },
  data()
  {
    return {
      fields: [
        {
          key: 'serialNumber',
          label: 'S/N'
        },
        {
          key: 'group_name',
          label: 'Group Name'
        },
        {
          key: 'number_of_staffs',
          label: 'Number Of Staffs'
        },

        {
          key: 'created_at',
          label: 'Date Created'
        }
      ],
      pageSize: 20,
      currentPage: 1,
      totalRecords: 0,
      companyGroups: [],
      fetchingGroups: true,
      search: '',
      debounce: null
    }
  },
  created()
  {
    this.fetchCompanyGroups()
  },
  computed: {
    filteredCompanyGroups()
    {
      const search = this.search.toLowerCase()

      return this.companyGroups.filter((eachGroup) =>
      {
        return eachGroup.toLowerCase().includes(search)
      })
    }
  },
  methods: {
    fetchCompanyGroups(reset = false)
    {
      if (reset) {
        this.currentPage = 1
      }

      this.fetchingGroups = true
      const url = `v1/corporates/${this.$route.params.companyId}/groups?metadata=true&page=${this.currentPage}&limit=${this.pageSize}`
      const request = this.search
        ? this.axios.get(url, { search: this.search || '' })
        : this.axios.get(url)
      request
        .then((res) =>
        {
          this.companyGroups = res.data.data
          this.totalRecords = res.data?.metadata?.total
          return this.partners
        })
        .finally(() => (this.fetchingGroups = false))
    },
    viewCorporateGroup(companyGroup)
    {
      this.$router.push({
        name: 'SingleCompanyGroups',
        params: { groupId: companyGroup.id, companyId: this.$route.params.companyId }
      })
    }
  },
  watch: {
    search()
    {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() =>
      {
        this.fetchNewPartners(true)
      }, 600)
    },
    currentPage()
    {
      this.fetchNewPartners()
    }
  }
}
</script>

<style scoped lang="scss">
@use 'src/assets/scss/b-table';
</style>
